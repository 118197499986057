import { Alert, Divider } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { connect } from "react-redux";
import PreviewPdfUrl from "./common/components/PreviewPdfUrl";

const DocumentItem = (props) => {
  const { document } = props;

  const [viewDocument, setViewDocument] = useState(false);
  return (
    <React.Fragment>
      <div className="justify-content-center text-center">
        {!document?.isDeleted ? (
          <>
            {!!document?.isDocumentValid && (
              <Alert severity="success">
                <strong>This document is valid.</strong>
              </Alert>
            )}
            {!document?.isDocumentValid && (
              <Alert severity="error">
                <strong>The expiration date of this document has passed.</strong>
              </Alert>
            )}
          </>
        ) : (
          <>
            <Alert severity="error">This document has been deleted</Alert>
          </>
        )}
      </div>
      <Divider className="mt-3" />
      <div className="row mt-2">
        <div className="col-lg-4">
          {" "}
          <strong>Owner:</strong>{" "}
        </div>
        <div className="col-lg-8">{document?.employeeNames || ""}</div>
        <div className="col-lg-12">
          {" "}
          <Divider className="mt-1" />
        </div>
        {!!document?.createdNames && (
          <>
            <div className="col-lg-4">
              {" "}
              <strong>Prepared By:</strong>{" "}
            </div>
            <div className="col-lg-8">{document?.createdNames || ""}</div>
            <div className="col-lg-12">
              {" "}
              <Divider className="mt-1" />
            </div>
            {/* <div className="col-lg-4">
              {" "}
              <strong>Created On:</strong>{" "}
            </div>
            <div className="col-lg-8">
              {moment(document?.createdOn).format("MMMM Do, YYYY HH:mm") || ""}
            </div>
            <div className="col-lg-12">
              {" "}
              <Divider className="mt-1" />
            </div> */}
          </>
        )}

        {!!document?.approvedNames && (
          <>
            <div className="col-lg-4">
              {" "}
              <strong>Approved By:</strong>{" "}
            </div>
            <div className="col-lg-8">{document?.approvedNames || ""}</div>
            <div className="col-lg-12">
              {" "}
              <Divider className="mt-1" />
            </div>
            <div className="col-lg-4">
              {" "}
              <strong>Approved On:</strong>{" "}
            </div>
            <div className="col-lg-8 text-success">
              {moment(document?.approvedOn).format("MMMM Do, YYYY HH:mm") || ""}
            </div>
            <div className="col-lg-12">
              {" "}
              <Divider className="mt-1" />
            </div>
          </>
        )}

        <div className="col-lg-4">
          {" "}
          <strong>Doc. Name:</strong>{" "}
        </div>
        <div className="col-lg-8 text-truncate">
          <span className="text-truncate">{document?.docName || ""}</span>
        </div>
        <div className="col-lg-12">
          {" "}
          <Divider className="mt-1" />
        </div>
        <div className="col-lg-4">
          {" "}
          <strong>Doc. Module:</strong>{" "}
        </div>
        <div className="col-lg-8">{document?.docModule || ""}</div>
        <div className="col-lg-12">
          {" "}
          <Divider className="mt-1" />
        </div>
        <div className="col-lg-4">
          {" "}
          <strong>Doc. Type:</strong>{" "}
        </div>
        <div className="col-lg-8">{document?.docFormat || ""}</div>
        <div className="col-lg-12">
          {" "}
          <Divider className="mt-1" />
        </div>

        {!!document?.validatedOn && (
          <>
            <div className="col-lg-4">
              {" "}
              <strong>Issued On:</strong>{" "}
            </div>
            <div className="col-lg-8 text-info">
              {moment(document?.validatedOn).format("MMMM Do, YYYY HH:mm") ||
                ""}
            </div>
            <div className="col-lg-12">
              {" "}
              <Divider className="mt-1" />
            </div>
          </>
        )}
        {!!document?.expiredOn && (
          <>
            <div className="col-lg-4">
              {" "}
              <strong>validity Until:</strong>{" "}
            </div>
            <div className="col-lg-8 text-danger">
              {moment(document?.expiredOn).format("MMMM Do, YYYY HH:mm") || ""}
            </div>
            <div className="col-lg-12">
              {" "}
              <Divider className="mt-1" />
            </div>
          </>
        )}
        <div className="col-lg-12 mt-3">
          <button
            onClick={() => setViewDocument(true)}
            type="button"
            className="btn btn-success btn-block text-uppercase btn-sm   text-center"
          >
            Open document
          </button>

          {viewDocument && (
            <PreviewPdfUrl
              showDocument={viewDocument}
              setShowDocument={setViewDocument}
              document={{
                name: `View ${document?.docName}`,
                url:
                  process.env.REACT_APP_FILES_URL + "/preview/" + document.id,
              }}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ document }) => {
  return { document };
};
export default connect(mapStateToProps, {})(DocumentItem);
