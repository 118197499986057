import { defaultState } from "./state";
import structure from "./action-types";
import commonTypes from "../common/action-types";
const types = { ...commonTypes, ...structure };

const units = (unitsState = defaultState.units, action) => {
  switch (action.type) {
    case types.SET_UNITS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.units;

    default:
      return unitsState;
  }
};

const treeUnits = (treeUnitsState = defaultState.treeUnits, action) => {
  switch (action.type) {
    case types.SET_TREE_UNITS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.treeUnits;

    default:
      return treeUnitsState;
  }
};

const selectedUnit = (
  selectedUnitState = defaultState.selectedUnit,
  action
) => {
  switch (action.type) {
    case types.SELECTED_UNIT:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.selectedUnit;

    default:
      return selectedUnitState;
  }
};

const searchUnit = (searchUnitState = defaultState.searchUnit, action) => {
  switch (action.type) {
    case types.SEARCH_UNIT:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.searchUnit;

    default:
      return searchUnitState;
  }
};

const searchPosition = (
  searchPositionState = defaultState.searchPosition,
  action
) => {
  switch (action.type) {
    case types.SEARCH_POSITION:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.searchPosition;

    default:
      return searchPositionState;
  }
};
const searchCurrentPositions = (
  searchCurrentPositionsState = defaultState.searchCurrentPositions,
  action
) => {
  switch (action.type) {
    case types.SEARCH_CURRENT_POSITIONS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.searchCurrentPositions;

    default:
      return searchCurrentPositionsState;
  }
};

const unitTypes = (unitTypesState = defaultState.unitTypes, action) => {
  switch (action.type) {
    case types.SET_UNIT_TYPES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.unitTypes;

    default:
      return unitTypesState;
  }
};
const entitySectorPositions = (
  entitySectorPositionsState = defaultState.entitySectorPositions,
  action
) => {
  switch (action.type) {
    case types.SET_ENTITY_SECTOR_POSITIONS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.entitySectorPositions;

    default:
      return entitySectorPositionsState;
  }
};

const positions = (positionsState = defaultState.positions, action) => {
  switch (action.type) {
    case types.SET_POSITIONS:
      return action.data;

    case types.ADD_POSITION:
      const tmpPositionsStateState = [...positionsState];
      tmpPositionsStateState.unshift(action.data);
      return tmpPositionsStateState;

    case types.DELETE_POSITION:
      const tmpRemovePositions = [...positionsState];
      const index = tmpRemovePositions.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpRemovePositions.splice(index, 1);

      return tmpRemovePositions;

    case types.UPDATE_POSITION: {
      const tmpPositionsStateState = [...positionsState];
      const index = tmpPositionsStateState.findIndex(
        ({ id }) => id === action.data.id
      );
      tmpPositionsStateState[index] = action.data;

      return tmpPositionsStateState;
    }

    case types.SET_POSITION_PLANNER || types.SET_POSITION_HEAD_UNITY: {
      const tmpPositionsStateState = [...positionsState];

      const index = tmpPositionsStateState.findIndex(
        ({ id }) => id === action.data.id
      );
      tmpPositionsStateState[index] = action.data;

      return tmpPositionsStateState;
    }

    case types.CLEAN_STATE:
      return defaultState.positions;

    default:
      return positionsState;
  }
};

const salaryStructures = (
  salaryStructuresState = defaultState.salaryStructures,
  action
) => {
  switch (action.type) {
    case types.SET_SALARY_STRUCTURE:
      return action.data;

    case types.ADD_SALARY_STRUCTURE: {
      const tmpSalaryStructuresState = [...salaryStructuresState];
      tmpSalaryStructuresState.unshift(action.data);
      return tmpSalaryStructuresState;
    }

    case types.UPDATE_SALARY_STRUCTURE: {
      const tmpSalaryStructuresState = [...salaryStructuresState];
      const index = tmpSalaryStructuresState.findIndex(
        ({ id }) => id === action.data.id
      );
      tmpSalaryStructuresState[index] = action.data;

      return tmpSalaryStructuresState;
    }
    case types.DELETE_SALARY_STRUCTURE: {
      const tmpSalaryStructuresState = [...salaryStructuresState];
      const index = tmpSalaryStructuresState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpSalaryStructuresState.splice(index, 1);

      return tmpSalaryStructuresState;
    }

    case types.CLEAN_STATE:
      return defaultState.salaryStructures;

    default:
      return salaryStructuresState;
  }
};

const employeesGroups = (
  employeesGroupsState = defaultState.employeesGroups,
  action
) => {
  switch (action.type) {
    case types.SET_EMPLOYEE_GROUPS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.employeesGroups;

    default:
      return employeesGroupsState;
  }
};

const vJobClassifications = (
  vJobClassificationsState = defaultState.vJobClassifications,
  action
) => {
  switch (action.type) {
    case types.SET_VJOB_CLASSIFICATIONS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.vJobClassifications;

    default:
      return vJobClassificationsState;
  }
};

const hiringModes = (hiringModesState = defaultState.hiringModes, action) => {
  switch (action.type) {
    case types.SET_HIRING_MODES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.hiringModes;

    default:
      return hiringModesState;
  }
};

const echelons = (echelonsState = defaultState.echelons, action) => {
  switch (action.type) {
    case types.SET_ECHELONS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.echelons;

    default:
      return echelonsState;
  }
};

const banks = (banksState = defaultState.banks, action) => {
  switch (action.type) {
    case types.SET_BANKS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.banks;

    default:
      return banksState;
  }
};

const medicalInsuranceTypes = (
  medicalInsuranceTypesState = defaultState.medicalInsuranceTypes,
  action
) => {
  switch (action.type) {
    case types.SET_MEDICAL_INSURANCE_TYPES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.medicalInsuranceTypes;

    default:
      return medicalInsuranceTypesState;
  }
};

const levels = (levelsState = defaultState.levels, action) => {
  switch (action.type) {
    case types.SET_LEVELS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.levels;

    default:
      return levelsState;
  }
};

const positionEmployees = (
  positionEmployeesState = defaultState.positionEmployees,
  action
) => {
  switch (action.type) {
    case types.SET_POSITION_EMPLOYEES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.positionEmployees;

    default:
      return positionEmployeesState;
  }
};

const supervisorPositions = (
  supervisorPositionsState = defaultState.supervisorPositions,
  action
) => {
  switch (action.type) {
    case types.SET_SUPERVISOR_POSITIONS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.supervisorPositions;

    default:
      return supervisorPositionsState;
  }
};

const professionalCertificates = (
  professionalCertificatesState = defaultState.professionalCertificates,
  action
) => {
  switch (action.type) {
    case types.SET_PROFESSIONAL_CERTIFICATES:
      return action.data;

    case types.ADD_PROFESSIONAL_CERTIFICATE:
      const tmpProfessionalCertificatesState = [
        ...professionalCertificatesState,
      ];

      if (
        !tmpProfessionalCertificatesState.find(
          ({ professionalCertificateId }) =>
            professionalCertificateId === action.data.professionalCertificateId
        )
      ) {
        tmpProfessionalCertificatesState.unshift(action.data);
      }

      return tmpProfessionalCertificatesState;

    case types.DELETE_PROFESSIONAL_CERTIFICATE:
      const tmpRemoveCertificate = [...professionalCertificatesState];
      const index = tmpRemoveCertificate.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpRemoveCertificate.splice(index, 1);

      return tmpRemoveCertificate;

    case types.CLEAN_STATE:
      return defaultState.professionalCertificates;

    default:
      return professionalCertificatesState;
  }
};

const degrees = (degreesState = defaultState.degrees, action) => {
  switch (action.type) {
    case types.SET_DEGREES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.degrees;

    default:
      return degreesState;
  }
};
const qualifications = (
  qualificationsState = defaultState.qualifications,
  action
) => {
  switch (action.type) {
    case types.SET_QUALIFICATIONS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.qualifications;

    default:
      return qualificationsState;
  }
};

const competencies = (
  competenciesState = defaultState.competencies,
  action
) => {
  switch (action.type) {
    case types.SET_COMPETENCIES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.competencies;

    default:
      return competenciesState;
  }
};

const certificateTypes = (
  certificateTypesState = defaultState.certificateTypes,
  action
) => {
  switch (action.type) {
    case types.SET_CERTIFICATE_TYPES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.certificateTypes;

    default:
      return certificateTypesState;
  }
};

const professionalCertificateTypes = (
  professionalCertificateTypesState = defaultState.professionalCertificateTypes,
  action
) => {
  switch (action.type) {
    case types.SET_PROFESSIONAL_CERTIFICATE_TYPES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.professionalCertificateTypes;

    default:
      return professionalCertificateTypesState;
  }
};

const positionKnowledges = (
  positionKnowledgesState = defaultState.positionKnowledges,
  action
) => {
  switch (action.type) {
    case types.SET_POSITION_KNOWLEDGES:
      return action.data;

    case types.ADD_POSITION_KNOWLEDGE:
      const tmpPositionKnowledgesState = [...positionKnowledgesState];

      if (
        !tmpPositionKnowledgesState.find(
          ({ knowledgeId }) => knowledgeId === action.data.knowledgeId
        )
      ) {
        tmpPositionKnowledgesState.unshift(action.data);
      }

      return tmpPositionKnowledgesState;

    case types.DELETE_POSITION_KNOWLEDGE:
      const tmpRemovePositionKnowledges = [...positionKnowledgesState];
      const index = tmpRemovePositionKnowledges.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpRemovePositionKnowledges.splice(index, 1);

      return tmpRemovePositionKnowledges;

    case types.CLEAN_STATE:
      return defaultState.positionKnowledges;

    default:
      return positionKnowledgesState;
  }
};

const searchEmployee = (
  searchEmployeeState = defaultState.searchEmployee,
  action
) => {
  switch (action.type) {
    case types.SEARCH_EMPLOYEES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.searchEmployee;

    default:
      return searchEmployeeState;
  }
};
const searchCurrentEmployees = (
  searchCurrentEmployeesState = defaultState.searchCurrentEmployees,
  action
) => {
  switch (action.type) {
    case types.SEARCH_CURRENT_EMPLOYEES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.searchCurrentEmployees;

    default:
      return searchCurrentEmployeesState;
  }
};

const employees = (employeesState = defaultState.employees, action) => {
  switch (action.type) {
    case types.SET_EMPLOYEES:
      return action.data;

    case types.ADD_EMPLOYEE:
      const tmpEmployeesState = [...employeesState];
      tmpEmployeesState.unshift(action.data);
      return tmpEmployeesState;

    case types.DELETE_POSITION:
      const tmpRemoveEmployee = [...employeesState];
      const index = tmpRemoveEmployee.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpRemoveEmployee.splice(index, 1);

      return tmpRemoveEmployee;

    case types.UPDATE_EMPLOYEE: {
      const tmpEmployeesState = [...employeesState];
      const index = tmpEmployeesState.findIndex(
        ({ id }) => id === action.data.id
      );
      tmpEmployeesState[index] = action.data;

      return tmpEmployeesState;
    }

    case types.CLEAN_STATE:
      return defaultState.employees;

    default:
      return employeesState;
  }
};

const nationalIdDetail = (
  nationalIdDetailState = defaultState.nationalIdDetail,
  action
) => {
  switch (action.type) {
    case types.SET_NATIONAL_ID_DETAIL:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.nationalIdDetail;

    default:
      return nationalIdDetailState;
  }
};

const employee = (employeeState = defaultState.employee, action) => {
  switch (action.type) {
    case types.SET_EMPLOYEE:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.employee;

    default:
      return employeeState;
  }
};
const salaryIndexGrid = (
  salaryIndexGridState = defaultState.salaryIndexGrid,
  action
) => {
  switch (action.type) {
    case types.SET_SALARAY_INDEX_GRID:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.salaryIndexGrid;

    default:
      return salaryIndexGridState;
  }
};

const jobClassifications = (
  jobClassificationsState = defaultState.jobClassifications,
  action
) => {
  switch (action.type) {
    case types.SET_JOB_CLASSIFICATIONS:
      return action.data;

    case types.UPDATE_JOB_CLASSIFICATION: {
      const tmpJobClassificationsState = [...jobClassificationsState];
      const index = tmpJobClassificationsState.findIndex(
        ({ id }) => id === action.data.id
      );
      tmpJobClassificationsState[index] = action.data;

      return tmpJobClassificationsState;
    }

    case types.CLEAN_STATE:
      return defaultState.jobClassifications;

    default:
      return jobClassificationsState;
  }
};

const jobClassificationAllowances = (
  jobClassificationAllowancesState = defaultState.jobClassificationAllowances,
  action
) => {
  switch (action.type) {
    case types.SET_JOB_CLASSIFICATION_ALLOWANCES:
      return action.data;
    case types.ADD_JOB_CLASSIFICATION_ALLOWANCE: {
      const tmpJobClassificationAllowancesState = [
        ...jobClassificationAllowancesState,
      ];
      tmpJobClassificationAllowancesState.unshift(action.data);
      return tmpJobClassificationAllowancesState;
    }

    case types.UPDATE_JOB_CLASSIFICATION_ALLOWANCE: {
      const tmpJobClassificationAllowancesState = [
        ...jobClassificationAllowancesState,
      ];
      const index = tmpJobClassificationAllowancesState.findIndex(
        ({ id }) => id === action.data.id
      );
      tmpJobClassificationAllowancesState[index] = action.data;

      return tmpJobClassificationAllowancesState;
    }

    case types.DELETE_JOB_CLASSIFICATION_ALLOWANCE:
      const tmpJobClassificationAllowancesState = [
        ...jobClassificationAllowancesState,
      ];
      const index = tmpJobClassificationAllowancesState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpJobClassificationAllowancesState.splice(index, 1);

      return tmpJobClassificationAllowancesState;

    case types.CLEAN_STATE:
      return defaultState.jobClassificationAllowances;

    default:
      return jobClassificationAllowancesState;
  }
};

const unitAllowances = (
  unitAllowancesState = defaultState.unitAllowances,
  action
) => {
  switch (action.type) {
    case types.SET_UNIT_ALLOWANCES:
      return action.data;
    case types.ADD_UNIT_ALLOWANCE: {
      const tmpUnitAllowancesState = [...unitAllowancesState];
      tmpUnitAllowancesState.unshift(action.data);
      return tmpUnitAllowancesState;
    }

    case types.UPDATE_UNIT_ALLOWANCE: {
      const tmpUnitAllowancesState = [...unitAllowancesState];
      const index = tmpUnitAllowancesState.findIndex(
        ({ id }) => id === action.data.id
      );
      tmpUnitAllowancesState[index] = action.data;

      return tmpUnitAllowancesState;
    }

    case types.DELETE_UNIT_ALLOWANCE:
      const tmpUnitAllowancesState = [...unitAllowancesState];
      const index = tmpUnitAllowancesState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpUnitAllowancesState.splice(index, 1);

      return tmpUnitAllowancesState;

    case types.CLEAN_STATE:
      return defaultState.unitAllowances;

    default:
      return unitAllowancesState;
  }
};
//entitySectorAllowances

const entitySectorAllowances = (
  entitySectorAllowancesState = defaultState.entitySectorAllowances,
  action
) => {
  switch (action.type) {
    case types.SET_ENTITY_SECTOR_ALLOWANCES:
      return action.data;
    case types.ADD_ENTITY_SECTOR_ALLOWANCE: {
      const tmpEntitySectorAllowancesState = [...entitySectorAllowancesState];
      tmpEntitySectorAllowancesState.unshift(action.data);
      return tmpEntitySectorAllowancesState;
    }

    case types.DELETE_ENTITY_SECTOR_ALLOWANCE:
      const tmpEntitySectorAllowancesState = [...entitySectorAllowancesState];
      const index = tmpEntitySectorAllowancesState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpEntitySectorAllowancesState.splice(index, 1);

      return tmpEntitySectorAllowancesState;

    case types.CLEAN_STATE:
      return defaultState.unitAllowances;

    default:
      return entitySectorAllowancesState;
  }
};
//positionAllowances

const positionAllowances = (
  positionAllowancesState = defaultState.positionAllowances,
  action
) => {
  switch (action.type) {
    case types.SET_POSITION_ALLOWANCES:
      return action.data;
    case types.ADD_POSITION_ALLOWANCE: {
      const tmpPositionAllowancesState = [...positionAllowancesState];
      tmpPositionAllowancesState.unshift(action.data);
      return tmpPositionAllowancesState;
    }

    case types.DELETE_POSITION_ALLOWANCE:
      const tmpPositionAllowancesState = [...positionAllowancesState];
      const index = tmpPositionAllowancesState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpPositionAllowancesState.splice(index, 1);

      return tmpPositionAllowancesState;

    case types.CLEAN_STATE:
      return defaultState.positionAllowances;

    default:
      return positionAllowancesState;
  }
};
//jobFields

const sharedPositionAllowances = (
  sharedPositionAllowancesState = defaultState.sharedPositionAllowances,
  action
) => {
  switch (action.type) {
    case types.SET_SHARED_POSITION_ALLOWANCES:
      return action.data;
    case types.ADD_SHARED_POSITION_ALLOWANCE: {
      const tmpSharedPositionAllowancesState = [
        ...sharedPositionAllowancesState,
      ];
      tmpSharedPositionAllowancesState.unshift(action.data);
      return tmpSharedPositionAllowancesState;
    }

    case types.DELETE_SHARED_POSITION_ALLOWANCE:
      const tmpSharedPositionAllowancesState = [
        ...sharedPositionAllowancesState,
      ];
      const index = tmpSharedPositionAllowancesState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpSharedPositionAllowancesState.splice(index, 1);

      return tmpSharedPositionAllowancesState;

    case types.CLEAN_STATE:
      return defaultState.sharedPositionAllowances;

    default:
      return sharedPositionAllowancesState;
  }
};

const jobFields = (jobFieldsState = defaultState.jobFields, action) => {
  switch (action.type) {
    case types.SET_JOB_FIELDS:
      return action.data;

    case types.ADD_JOB_FIELD: {
      const tmpjobFieldsState = [...jobFieldsState];
      tmpjobFieldsState.unshift(action.data);
      return tmpjobFieldsState;
    }

    case types.CLEAN_STATE:
      return defaultState.jobFields;

    default:
      return jobFieldsState;
  }
};

const entitySectors = (
  entitySectorsState = defaultState.entitySectors,
  action
) => {
  switch (action.type) {
    case types.SET_ENTITY_SECTORS:
      return action.data;

    case types.UPDATE_ENTITY_SECTOR: {
      const tmpEntitySectorsState = [...entitySectorsState];
      const index = tmpEntitySectorsState.findIndex(
        ({ id }) => id === action.data.id
      );
      tmpEntitySectorsState[index] = action.data;

      return tmpEntitySectorsState;
    }

    case types.CLEAN_STATE:
      return defaultState.entitySectors;

    default:
      return entitySectorsState;
  }
};
//position
const position = (positionState = defaultState.position, action) => {
  switch (action.type) {
    case types.SET_POSITION:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.position;

    default:
      return positionState;
  }
};

const unitWithholds = (
  unitWithholdsState = defaultState.unitWithholds,
  action
) => {
  switch (action.type) {
    case types.SET_UNIT_WITHHOLDS:
      return action.data;
    case types.ADD_UNIT_WITHHOLD: {
      const tmpUnitCreditorsState = [...unitWithholdsState];
      tmpUnitCreditorsState.unshift(action.data);
      return tmpUnitCreditorsState;
    }

    case types.UPDATE_UNIT_WITHHOLD: {
      const tmpUnitCreditorsState = [...unitWithholdsState];
      const index = tmpUnitCreditorsState.findIndex(
        ({ id }) => id === action.data.id
      );
      tmpUnitCreditorsState[index] = action.data;

      return tmpUnitCreditorsState;
    }

    case types.DELETE_UNIT_WITHHOLD:
      const tmpUnitCreditorsState = [...unitWithholdsState];
      const index = tmpUnitCreditorsState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpUnitCreditorsState.splice(index, 1);

      return tmpUnitCreditorsState;

    case types.CLEAN_STATE:
      return defaultState.unitWithholds;

    default:
      return unitWithholdsState;
  }
};

const positionWithholds = (
  positionWithholdsState = defaultState.positionWithholds,
  action
) => {
  switch (action.type) {
    case types.SET_POSITION_WITHHOLDS:
      return action.data;
    case types.ADD_POSITION_WITHHOLD: {
      const tmpPositionWithholdsState = [...positionWithholdsState];
      tmpPositionWithholdsState.unshift(action.data);
      return tmpPositionWithholdsState;
    }

    case types.UPDATE_POSITION_WITHHOLD: {
      const tmpPositionWithholdsState = [...positionWithholdsState];
      const index = tmpPositionWithholdsState.findIndex(
        ({ id }) => id === action.data.id
      );
      tmpPositionWithholdsState[index] = action.data;

      return tmpPositionWithholdsState;
    }

    case types.DELETE_POSITION_WITHHOLD:
      const tmpPositionWithholdsState = [...positionWithholdsState];
      const index = tmpPositionWithholdsState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpPositionWithholdsState.splice(index, 1);

      return tmpPositionWithholdsState;

    case types.CLEAN_STATE:
      return defaultState.positionWithholds;

    default:
      return positionWithholdsState;
  }
};

const sharedPositionWithholds = (
  sharedPositionWithholdsState = defaultState.sharedPositionWithholds,
  action
) => {
  switch (action.type) {
    case types.SET_SHARED_POSITION_WITHHOLDS:
      return action.data;
    case types.ADD_SHARED_POSITION_WITHHOLD: {
      const tmpSharedPositionWithholdsState = [...sharedPositionWithholdsState];
      tmpSharedPositionWithholdsState.unshift(action.data);
      return tmpSharedPositionWithholdsState;
    }

    case types.UPDATE_SHARED_POSITION_WITHHOLD: {
      const tmpSharedPositionWithholdsState = [...sharedPositionWithholdsState];
      const index = tmpSharedPositionWithholdsState.findIndex(
        ({ id }) => id === action.data.id
      );
      tmpSharedPositionWithholdsState[index] = action.data;

      return tmpSharedPositionWithholdsState;
    }

    case types.DELETE_SHARED_POSITION_WITHHOLD:
      const tmpSharedPositionWithholdsState = [...sharedPositionWithholdsState];
      const index = tmpSharedPositionWithholdsState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpSharedPositionWithholdsState.splice(index, 1);

      return tmpSharedPositionWithholdsState;

    case types.CLEAN_STATE:
      return defaultState.sharedPositionWithholds;

    default:
      return sharedPositionWithholdsState;
  }
};

//jobClassificationEmployees

const jobClassificationEmployees = (
  jobClassificationEmployeesState = defaultState.jobClassificationEmployees,
  action
) => {
  switch (action.type) {
    case types.SET_JOB_CLASSIFICATION_EMPOYEES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.jobClassificationEmployees;

    default:
      return jobClassificationEmployeesState;
  }
};

//jobClassificationQualifications

const jobClassificationQualifications = (
  jobClassificationQualificationsState = defaultState.jobClassificationQualifications,
  action
) => {
  switch (action.type) {
    case types.SET_JOB_CLASSIFICATION_QUALIFICATIONS:
      return action.data;

    case types.ADD_JOB_CLASSIFICATION_QUALIFICATION:
      const tmpJobClassificationQualificationsState = [
        ...jobClassificationQualificationsState,
      ];
      tmpJobClassificationQualificationsState.unshift(action.data);
      return tmpJobClassificationQualificationsState;

    case types.DELETE_JOB_CLASSIFICATION_QUALIFICATION:
      const tmprmJobClassificationQualificationsState = [
        ...jobClassificationQualificationsState,
      ];
      const index = tmprmJobClassificationQualificationsState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmprmJobClassificationQualificationsState.splice(index, 1);

      return tmprmJobClassificationQualificationsState;

    case types.CLEAN_STATE:
      return defaultState.jobClassificationQualifications;

    default:
      return jobClassificationQualificationsState;
  }
};

const jobClassificationExperiences = (
  jobClassificationExperiencesState = defaultState.jobClassificationExperiences,
  action
) => {
  switch (action.type) {
    case types.SET_JOB_CLASSIFICATION_EXPERIENCES:
      return action.data;

    case types.ADD_JOB_CLASSIFICATION_EXPERIENCE:
      const tmpJobClassificationExperiences = [
        ...jobClassificationExperiencesState,
      ];
      tmpJobClassificationExperiences.unshift(action.data);
      return tmpJobClassificationExperiences;

    case types.DELETE_JOB_CLASSIFICATION_EXPERIENCE:
      const tmprmJobClassificationExperiences = [
        ...jobClassificationExperiencesState,
      ];
      const index = tmprmJobClassificationExperiences.findIndex(
        ({ id }) => id === action.data.id
      );

      tmprmJobClassificationExperiences.splice(index, 1);

      return tmprmJobClassificationExperiences;

    case types.CLEAN_STATE:
      return defaultState.jobClassificationExperiences;

    default:
      return jobClassificationExperiencesState;
  }
};
//jobClassificationCompetencies
const jobClassificationCompetencies = (
  jobClassificationCompetenciesState = defaultState.jobClassificationCompetencies,
  action
) => {
  switch (action.type) {
    case types.SET_JOB_CLASSIFICATION_COMPETENCIES:
      return action.data;

    case types.ADD_JOB_CLASSIFICATION_COMPETENCY:
      const tmpJobClassificationCompetencies = [
        ...jobClassificationCompetenciesState,
      ];
      tmpJobClassificationCompetencies.unshift(action.data);
      return tmpJobClassificationCompetencies;

    case types.DELETE_JOB_CLASSIFICATION_COMPETENCY:
      const tmprmJobClassificationCompetencies = [
        ...jobClassificationCompetenciesState,
      ];
      const index = tmprmJobClassificationCompetencies.findIndex(
        ({ id }) => id === action.data.id
      );

      tmprmJobClassificationCompetencies.splice(index, 1);

      return tmprmJobClassificationCompetencies;

    case types.CLEAN_STATE:
      return defaultState.jobClassificationCompetencies;

    default:
      return jobClassificationCompetenciesState;
  }
};
//jobClassificationCertificates

const jobClassificationCertificates = (
  jobClassificationCertificatesState = defaultState.jobClassificationCertificates,
  action
) => {
  switch (action.type) {
    case types.SET_JOB_CLASSIFICATION_CERTIFICATES:
      return action.data;

    case types.ADD_JOB_CLASSIFICATION_CERTIFICATE:
      const tmpJobClassificationCertificatesState = [
        ...jobClassificationCertificatesState,
      ];
      tmpJobClassificationCertificatesState.unshift(action.data);
      return tmpJobClassificationCertificatesState;

    case types.DELETE_JOB_CLASSIFICATION_CERTIFICATE:
      const tmprmJobClassificationCertificatesState = [
        ...jobClassificationCertificatesState,
      ];
      const index = tmprmJobClassificationCertificatesState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmprmJobClassificationCertificatesState.splice(index, 1);

      return tmprmJobClassificationCertificatesState;

    case types.CLEAN_STATE:
      return defaultState.jobClassificationCertificates;

    default:
      return jobClassificationCertificatesState;
  }
};
const certificateFields = (
  certificateFieldsState = defaultState.certificateFields,
  action
) => {
  switch (action.type) {
    case types.SET_CERTIFICATE_FIELDS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.certificateFields;

    default:
      return certificateFieldsState;
  }
};

const certificates = (
  certificatesState = defaultState.certificates,
  action
) => {
  switch (action.type) {
    case types.SET_CERTIFICATES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.certificates;

    default:
      return certificatesState;
  }
};

const positionExperiences = (
  positionExperiencesState = defaultState.positionExperiences,
  action
) => {
  switch (action.type) {
    case types.SET_POSITION_EXPERIENCES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.positionExperiences;

    default:
      return positionExperiencesState;
  }
};

const positionCompetencies = (
  positionCompetenciesState = defaultState.positionCompetencies,
  action
) => {
  switch (action.type) {
    case types.SET_POSITION_COMPETENCIES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.positionCompetencies;

    default:
      return positionCompetenciesState;
  }
};

const positionQualifications = (
  positionQualificationsState = defaultState.positionQualifications,
  action
) => {
  switch (action.type) {
    case types.SET_POSITION_QUALIFICATIONS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.positionQualifications;

    default:
      return positionQualificationsState;
  }
};

const positionCertificates = (
  positionCertificatesState = defaultState.positionCertificates,
  action
) => {
  switch (action.type) {
    case types.SET_POSITION_CERTIFICATES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.positionCertificates;

    default:
      return positionCertificatesState;
  }
};

const sharedScopes = (
  sharedScopesState = defaultState.sharedScopes,
  action
) => {
  switch (action.type) {
    case types.SET_SHARED_SCOPES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.sharedScopes;

    default:
      return sharedScopesState;
  }
};

const verifyRssbNumber = (
  verifyRssbNumberState = defaultState.verifyRssbNumber,
  action
) => {
  switch (action.type) {
    case types.SET_VERIFY_RSSB_NUMBER:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.verifyRssbNumber;

    default:
      return verifyRssbNumberState;
  }
};

export default {
  units,
  treeUnits,
  unitTypes,
  positions,
  selectedUnit,
  searchUnit,
  searchPosition,
  searchCurrentPositions,
  salaryStructures,
  employeesGroups,
  hiringModes,
  echelons,
  levels,
  positionEmployees,
  supervisorPositions,
  positionQualifications,
  degrees,
  qualifications,
  professionalCertificates,
  competencies,
  certificateTypes,
  professionalCertificateTypes,
  positionKnowledges,
  banks,
  medicalInsuranceTypes,
  ///////////////////////////////////EMPLOYEEES
  searchEmployee,
  searchCurrentEmployees,
  employees,
  nationalIdDetail,
  employee,
  salaryIndexGrid,
  jobClassifications,
  jobClassificationAllowances,
  unitAllowances,
  unitWithholds,
  jobFields,
  entitySectors,
  vJobClassifications,
  position,
  positionAllowances,
  positionWithholds,
  jobClassificationEmployees,
  jobClassificationQualifications,
  jobClassificationExperiences,
  jobClassificationCompetencies,
  certificateFields,
  certificates,
  jobClassificationCertificates,
  positionExperiences,
  positionCompetencies,
  positionCertificates,
  entitySectorAllowances,
  sharedScopes,
  entitySectorPositions,
  sharedPositionAllowances,
  sharedPositionWithholds,
  verifyRssbNumber,
};
