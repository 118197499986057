export const defaultState = {
  salary: null,
  payHistories: [],
  previewEmployeePaySlip: null,
  creditors: [],
  banks: [],
  employeeBankAccounts: [],
  employeeWithholds: [],
  employeeAllowances: [],
  employeeArrears: [],
  selectedContract: {},
  calculationMethods: [],
  basePayrollComponents: [],
};
