export const defaultState = {
  units: [],
  unitTypes: [],
  treeUnits: {},
  selectedUnit: {},
  positions: [],
  searchUnit: null,
  searchPosition: null,
  searchCurrentPositions: [],
  salaryStructures: [],
  employeesGroups: [],
  hiringModes: [],
  echelons: [],
  levels: [],
  positionEmployees: [],
  supervisorPositions: [],
  degrees: [],
  qualifications: [],
  professionalCertificates: [],
  knowledges: [],
  certificateTypes: [],
  professionalCertificateTypes: [],
  positionKnowledges: [],
  banks: [],
  medicalInsuranceTypes: [],
  ///////////////////////////////////EMPLOYEEES

  searchEmployee: null,
  searchCurrentEmployees: [],
  employees: [],
  nationalIdDetail: null,
  employee: null,
  salaryIndexGrid: null,
  jobClassifications: [],
  jobClassificationAllowances: [],
  unitAllowances: [],
  unitWithholds: [],
  jobFields: [],
  entitySectors: [],
  vJobClassifications: [],
  position: null,
  positionAllowances: [],
  positionWithholds: [],
  jobClassificationEmployees: [],
  jobClassificationQualifications: [],
  jobClassificationExperiences: [],
  jobClassificationCompetencies: [],
  competencies: [],
  certificateFields: [],
  certificates: [],
  jobClassificationCertificates: [],

  positionExperiences: [],
  positionQualifications: [],
  positionCertificates: [],
  positionCompetencies: [],
  entitySectorAllowances: [],
  sharedScopes: [],
  entitySectorPositions: [],
  sharedPositionAllowances: [],
  sharedPositionWithholds: [],
  verifyRssbNumber: null,
};
