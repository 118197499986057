import { defaultState } from "./state";
import rbmTypes from "./action-types";
import commonTypes from "../common/action-types";
const types = { ...commonTypes, ...rbmTypes };

const myActivities = (
  myActivitiesState = defaultState.myActivities,
  action
) => {
  switch (action.type) {
    case types.SET_MY_ACTIVITIES:
      return action.data;

    case types.UPDATE_ACTIVITY: {
      const tmpMyActivitiesState = [...myActivitiesState];

      const quarterIndex = tmpMyActivitiesState.findIndex(
        ({ quarterId }) => quarterId === action.data.quarterId
      );

      if (quarterIndex >= 0) {
        const activities = [
          ...(tmpMyActivitiesState[quarterIndex].activities || []),
        ];

        const activityIndex = activities.findIndex(
          ({ id }) => id === action.data.id
        );

        if (activityIndex >= 0) {
          const assignments = !!activities[activityIndex]?.assignments
            ? [...activities[activityIndex]?.assignments]
            : undefined;

          activities[activityIndex] = {
            ...action.data,
            assignments: assignments,
          };

          tmpMyActivitiesState[quarterIndex] = {
            ...tmpMyActivitiesState[quarterIndex],
            activities: [...activities],
          };
        }
      }

      return tmpMyActivitiesState;
    }

    case types.CLEAN_STATE:
      return defaultState.myActivities;

    default:
      return myActivitiesState;
  }
};

const myContracts = (myContractsState = defaultState.myContracts, action) => {
  switch (action.type) {
    case types.SET_MY_CONTRACTS:
      return action.data;

    case types.UPDATE_CONTRACT: {
      const tmpMyContractsState = [...myContractsState];
      const index = tmpMyContractsState.findIndex(
        ({ id }) => id === action.data.id
      );

      if (index >= 0) tmpMyContractsState[index] = action.data;

      return tmpMyContractsState;
    }

    case types.CLEAN_STATE:
      return defaultState.myContracts;

    default:
      return myContractsState;
  }
};

const submissions = (submissionsState = defaultState.submissions, action) => {
  switch (action.type) {
    case types.SET_SUBMISSIONS:
      return action.data;

    case types.DELETE_SUBMISSION: {
      const tmpSubmissionsState = [...submissionsState];
      const index = tmpSubmissionsState.findIndex(({ id }) => id === action.id);
      tmpSubmissionsState.splice(index, 1);

      return tmpSubmissionsState;
    }

    case types.CLEAN_STATE:
      return defaultState.submissions;

    default:
      return submissionsState;
  }
};

const fiscalYears = (fiscalYearsState = defaultState.fiscalYears, action) => {
  switch (action.type) {
    case types.SET_FISCAL_YEARS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.fiscalYears;

    default:
      return fiscalYearsState;
  }
};

export default {
  myActivities,
  fiscalYears,
  myContracts,
  submissions,
};
