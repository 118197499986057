import { defaultState } from "./state";
import payroll from "./action-types";
import commonTypes from "../common/action-types";
const types = { ...commonTypes, ...payroll };

const salary = (salaryState = defaultState.salary, action) => {
  switch (action.type) {
    case types.SET_SALARY:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.salary;

    default:
      return salaryState;
  }
};

const creditors = (creditorsState = defaultState.creditors, action) => {
  switch (action.type) {
    case types.SET_CREDITORS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.creditors;

    default:
      return creditorsState;
  }
};

const employeeWithHolds = (
  employeeWithHoldsState = defaultState.employeeWithHolds,
  action
) => {
  switch (action.type) {
    case types.SET_EMPLOYEE_WITHHOLDS:
      return action.data;
    case types.ADD_EMPLOYEE_POSITION_WITHHOLDS:
      const tmpEmployeeWithHoldsState = [...employeeWithHoldsState];
      tmpEmployeeWithHoldsState.unshift(action.data);
      return tmpEmployeeWithHoldsState;

    case types.DELETE_EMPLOYEE_POSITION_WITHHOLDS:
      const tmpEmployeeWithholdsState = [...employeeWithHoldsState];
      const index = tmpEmployeeWithholdsState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpEmployeeWithholdsState.splice(index, 1);

      return tmpEmployeeWithholdsState;

    case types.CLEAN_STATE:
      return defaultState.employeeWithHolds;

    default:
      return employeeWithHoldsState;
  }
};

//payHistories

const payHistories = (
  payHistoriesState = defaultState.payHistories,
  action
) => {
  switch (action.type) {
    case types.SET_PAY_HISTORIES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.payHistories;

    default:
      return payHistoriesState;
  }
};
const previewEmployeePaySlip = (
  previewEmployeePaySlipState = defaultState.previewEmployeePaySlip,
  action
) => {
  switch (action.type) {
    case types.PREVIEW_EMPLOYEE_PAYSLIP:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.previewEmployeePaySlip;

    default:
      return previewEmployeePaySlipState;
  }
};
const banks = (banksState = defaultState.banks, action) => {
  switch (action.type) {
    case types.SET_BANKS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.banks;

    default:
      return banksState;
  }
};

const employeeBankAccounts = (
  employeeBankAccountsState = defaultState.employeeBankAccounts,
  action
) => {
  switch (action.type) {
    case types.SET_EMPLOYEE_BANK_ACCOUNTS:
      return action.data;

    case types.DELETE_EMPLOYEE_BANK_ACCOUNT: {
      const tmpEmployeeBankAccountsState = [...employeeBankAccountsState];
      const index = tmpEmployeeBankAccountsState.findIndex(
        ({ id }) => id === action.id
      );

      if (index < 0) return employeeBankAccountsState;

      tmpEmployeeBankAccountsState.splice(index, 1);

      return tmpEmployeeBankAccountsState;
    }

    case types.CLEAN_STATE:
      return defaultState.employeeBankAccounts;

    default:
      return employeeBankAccountsState;
  }
};

const employeeWithholds = (
  employeeWithholdsState = defaultState.employeeWithholds,
  action
) => {
  switch (action.type) {
    case types.SET_EMPLOYEE_WITHHOLDS:
      return action.data;
    case types.ADD_EMPLOYEE_WITHHOLD: {
      const tmpEmployeeWithholdsState = [...employeeWithholdsState];
      tmpEmployeeWithholdsState.unshift(action.data);
      return tmpEmployeeWithholdsState;
    }

    case types.UPDATE_EMPLOYEE_WITHHOLD: {
      const tmpEmployeeWithholdsState = [...employeeWithholdsState];
      const index = tmpEmployeeWithholdsState.findIndex(
        ({ id }) => id === action.data.id
      );
      tmpEmployeeWithholdsState[index] = action.data;

      return tmpEmployeeWithholdsState;
    }

    case types.DELETE_EMPLOYEE_WITHHOLD:
      const tmpEmployeeWithholdsState = [...employeeWithholdsState];
      const index = tmpEmployeeWithholdsState.findIndex(
        ({ id }) => id === action.data.id
      );

      tmpEmployeeWithholdsState.splice(index, 1);

      return tmpEmployeeWithholdsState;

    case types.CLEAN_STATE:
      return defaultState.employeeWithholds;

    default:
      return employeeWithholdsState;
  }
};
const employeeAllowances = (
  employeeAllowancesState = defaultState.employeeAllowances,
  action
) => {
  switch (action.type) {
    case types.SET_EMPLOYEE_ALLOWANCES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.employeeAllowances;

    default:
      return employeeAllowancesState;
  }
};

const employeeArrears = (
  employeeArrearsState = defaultState.employeeArrears,
  action
) => {
  switch (action.type) {
    case types.SET_EMPLOYEE_ARREARS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.employeeArrears;

    default:
      return employeeArrearsState;
  }
};
const selectedContract = (
  selectedContractState = defaultState.selectedContract,
  action
) => {
  switch (action.type) {
    case types.SET_SELECTED_CONTRACT:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.selectedContract;

    default:
      return selectedContractState;
  }
};
const calculationMethods = (
  currentState = defaultState.calculationMethods,
  action
) => {
  switch (action.type) {
    case types.SET_CALCULATION_METHODES:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.calculationMethods;

    default:
      return currentState;
  }
};

const basePayrollComponents = (
  currentState = defaultState.basePayrollComponents,
  action
) => {
  switch (action.type) {
    case types.SET_BASE_PAYROLL_COMPONENTS:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.basePayrollComponents;

    default:
      return currentState;
  }
};

export default {
  salary,
  employeeWithholds,
  payHistories,
  previewEmployeePaySlip,
  creditors,
  banks,
  employeeBankAccounts,
  employeeAllowances,
  employeeArrears,
  selectedContract,
  calculationMethods,
  basePayrollComponents,
};
