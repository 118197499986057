const types = {
  SET_SALARY: "SET_SALARY",
  ADD_EMPLOYEE_POSITION_WITHHOLDS: "ADD_EMPLOYEE_POSITION_WITHHOLDS",
  DELETE_EMPLOYEE_POSITION_WITHHOLDS: "DELETE_EMPLOYEE_POSITION_WITHHOLDS",
  SET_EMPLOYEE_PAYROLL_WITHHOLDS: "SET_EMPLOYEE_PAYROLL_WITHHOLDS",
  SET_PAY_HISTORIES: "SET_PAY_HISTORIES",
  PREVIEW_EMPLOYEE_PAYSLIP: "PREVIEW_EMPLOYEE_PAYSLIP",
  SET_CREDITORS: "SET_CREDITORS",
  SET_BANKS: "SET_BANKS",
  SET_EMPLOYEE_BANK_ACCOUNTS: "SET_EMPLOYEE_BANK_ACCOUNTS",
  DELETE_EMPLOYEE_BANK_ACCOUNT: "DELETE_EMPLOYEE_BANK_ACCOUNT",
  SET_EMPLOYEE_WITHHOLDS: "SET_EMPLOYEE_WITHHOLDS",
  ADD_EMPLOYEE_WITHHOLD: "ADD_EMPLOYEE_WITHHOLD",
  UPDATE_EMPLOYEE_WITHHOLD: "UPDATE_EMPLOYEE_WITHHOLD",
  DELETE_EMPLOYEE_WITHHOLD: "DELETE_EMPLOYEE_WITHHOLD",
  SET_EMPLOYEE_ALLOWANCES: "SET_EMPLOYEE_ALLOWANCES",
  SET_EMPLOYEE_ARREARS: "SET_EMPLOYEE_ARREARS",
  SET_SELECTED_CONTRACT: "SET_SELECTED_CONTRACT",
  SET_CALCULATION_METHODES: "SET_CALCULATION_METHODES",
  SET_BASE_PAYROLL_COMPONENTS: "SET_BASE_PAYROLL_COMPONENTS",
};

export default types;
