import { defaultState } from "./state";
import training from "./action-types";
import commonTypes from "../common/action-types";
const types = { ...commonTypes, ...training };

const myTrainingRequests = (
  myTrainingRequestsState = defaultState.myTrainingRequests,
  action
) => {
  switch (action.type) {
    case types.SET_TRAINING_REQUESTS: {
      return action.data;
    }

    case types.CLEAN_STATE:
      return defaultState.myTrainingRequests;
    default:
      return myTrainingRequestsState;
  }
};
const trainingRequest = (
  trainingRequestState = defaultState.trainingRequest,
  action
) => {
  switch (action.type) {
    case types.SET_TRAINING_REQUEST:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.trainingRequest;
    default:
      return trainingRequestState;
  }
};

export default {
  myTrainingRequests,
  trainingRequest,
};
