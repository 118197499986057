import React from "react";
import { Link } from "react-router-dom";
import logoSmall from "../../assets/logo-small.png";

import { AppBar, Box, Toolbar, Typography } from "@mui/material";

const Header = (props) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" elevation={0} className="app-bar">
        <Toolbar className="pr-2">
          <Typography component="div" sx={{ flexGrow: 1 }} className="left">
            <div className="row">
              <div className="col-lg-8 py-1">
                <img
                  src={logoSmall}
                  className="brand_logo mr-2 "
                  alt="Logo"
                />
                <strong>
                  <span style={{ fontWeight: "bolder" }}>IPPIS</span>{" "}
                </strong>
                |{" "}
                <span style={{ color: "#078ece" }} className="text-uppercase ">
                  Documents
                </span>{" "}
              </div>
              <div className="col-lg-4 py-1 text-right d-none d-md-inline">
                <div className="mr-2">
                  <a
                    className=" my-sm-0  text-uppercase  mx-1"
                    rel="noreferrer"
                    href="https://ippis.rw/"
                    target="_blank"
                  >
                    IPPIS
                  </a>{" "}
                  |{" "}
                  <a
                    className=" my-sm-0  text-uppercase mx-1"
                    rel="noreferrer"
                    href="http://recruitment.mifotra.gov.rw/"
                    target="_blank"
                  >
                    E-Recruitment
                  </a>{" "}
                  |{" "}
                  <Link
                    className=" my-sm-0  text-uppercase text-primary  mx-1"
                    to="https://selfservice.ippis.rw/"
                  >
                    Self-Service
                  </Link>
                </div>
              </div>
            </div>
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
