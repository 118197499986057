const types = {
  SET_TREE_UNITS: "SET_TREE_UNITS",
  SET_UNITS: "SET_UNITS",
  SET_UNIT_TYPES: "SET_UNIT_TYPES",
  DELETE_UNIT: "DELETE_UNIT",
  SELECTED_UNIT: "SELECTED_UNIT",
  SET_POSITIONS: "SET_POSITIONS",
  SET_ENTITY_SECTOR_POSITIONS: "SET_ENTITY_SECTOR_POSITIONS",
  SEARCH_UNIT: "SEARCH_UNIT",
  SEARCH_POSITION: "SEARCH_POSITION",
  SEARCH_CURRENT_POSITIONS: "SEARCH_CURRENT_POSITIONS",
  SET_SALARY_STRUCTURE: "SET_SALARY_STRUCTURE",
  SET_EMPLOYEE_GROUPS: "SET_EMPLOYEE_GROUPS",
  SET_HIRING_MODES: "SET_HIRING_MODES",
  ADD_POSITION: "ADD_POSITION",
  SET_LEVELS: "SET_LEVELS",
  SET_ECHELONS: "SET_ECHELONS",
  DELETE_POSITION: "DELETE_POSITION",
  SET_POSITION_PLANNER: "SET_POSITION_PLANNER",
  SET_POSITION_HEAD_UNITY: "SET_POSITION_HEAD_UNITY",
  SET_POSITION_EMPLOYEES: "SET_POSITION_EMPLOYEES",
  SET_SUPERVISOR_POSITIONS: "SET_SUPERVISOR_POSITIONS",
  UPDATE_POSITION: "UPDATE_POSITION",
  SET_DEGREES: "SET_DEGREES",
  SET_QUALIFICATIONS: "SET_QUALIFICATIONS",
  UPDATE_QUALIFICATION: "UPDATE_QUALIFICATION",
  ADD_QUALIFICATION: "ADD_QUALIFICATION",
  DELETE_QUALIFICATION: "DELETE_QUALIFICATION",
  SET_PROFESSIONAL_CERTIFICATES: "SET_PROFESSIONAL_CERTIFICATES",
  SET_CERTIFICATE_TYPES: "SET_CERTIFICATE_TYPES",
  SET_PROFESSIONAL_CERTIFICATE_TYPES: "SET_PROFESSIONAL_CERTIFICATE_TYPES",
  ADD_PROFESSIONAL_CERTIFICATE: "ADD_PROFESSIONAL_CERTIFICATE",
  DELETE_PROFESSIONAL_CERTIFICATE: "DELETE_PROFESSIONAL_CERTIFICATE",
  SET_POSITION_KNOWLEDGES: "SET_POSITION_KNOWLEDGES",
  DELETE_POSITION_KNOWLEDGE: "DELETE_POSITION_KNOWLEDGE",
  ADD_POSITION_KNOWLEDGE: "ADD_POSITION_KNOWLEDGE",
  SET_EMPLOYEES: "SET_EMPLOYEES",
  SEARCH_EMPLOYEES: "SEARCH_EMPLOYEES",
  SEARCH_CURRENT_EMPLOYEES: "SEARCH_CURRENT_EMPLOYEES",
  ADD_EMPLOYEE: "ADD_EMPLOYEE",
  UPDATE_EMPLOYEE: "UPDATE_EMPLOYEE",
  SET_NATIONAL_ID_DETAIL: "SET_NATIONAL_ID_DETAIL",
  SET_BANKS: "SET_BANKS",
  SET_MEDICAL_INSURANCE_TYPES: "SET_MEDICAL_INSURANCE_TYPES",
  SET_EMPLOYEE: "SET_EMPLOYEE",
  SET_SALARAY_INDEX_GRID: "SET_SALARAY_INDEX_GRID",
  SET_JOB_CLASSIFICATIONS: "SET_JOB_CLASSIFICATIONS",
  UPDATE_JOB_CLASSIFICATION: "UPDATE_JOB_CLASSIFICATION",
  SET_JOB_CLASSIFICATION_ALLOWANCES: "SET_JOB_CLASSIFICATION_ALLOWANCES",
  UPDATE_JOB_CLASSIFICATION_ALLOWANCE: "UPDATE_JOB_CLASSIFICATION_ALLOWANCE",
  DELETE_JOB_CLASSIFICATION_ALLOWANCE: "DELETE_JOB_CLASSIFICATION_ALLOWANCE",
  ADD_JOB_CLASSIFICATION_ALLOWANCE: "ADD_JOB_CLASSIFICATION_ALLOWANCE",
  SET_UNIT_ALLOWANCES: "SET_UNIT_ALLOWANCES",
  SET_UNIT_WITHHOLDS: "SET_UNIT_WITHHOLDS",
  ADD_UNIT_ALLOWANCE: "ADD_UNIT_ALLOWANCE",
  UPDATE_UNIT_ALLOWANCE: "UPDATE_UNIT_ALLOWANCE",
  DELETE_UNIT_ALLOWANCE: "DELETE_UNIT_ALLOWANCE",
  ADD_UNIT_WITHHOLD: "ADD_UNIT_WITHHOLD",
  UPDATE_UNIT_CREDITOR: "UPDATE_UNIT_CREDITOR",
  DELETE_UNIT_WITHHOLD: "DELETE_UNIT_WITHHOLD",
  SET_JOB_FIELDS: "SET_JOB_FIELDS",
  ADD_JOB_FIELD: "ADD_JOB_FIELD",
  SET_ENTITY_SECTORS: "SET_ENTITY_SECTORS",
  UPDATE_SALARY_STRUCTURE: "UPDATE_SALARY_STRUCTURE",
  ADD_SALARY_STRUCTURE: "ADD_SALARY_STRUCTURE",
  DELETE_SALARY_STRUCTURE: "DELETE_SALARY_STRUCTURE",
  SET_VJOB_CLASSIFICATIONS: "SET_VJOB_CLASSIFICATIONS",
  SET_POSITION: "SET_POSITION",
  SET_POSITION_ALLOWANCES: "SET_POSITION_ALLOWANCES",
  ADD_POSITION_ALLOWANCE: "ADD_POSITION_ALLOWANCE",
  DELETE_POSITION_ALLOWANCE: "DELETE_POSITION_ALLOWANCE",
  SET_POSITION_WITHHOLDS: "SET_POSITION_WITHHOLDS",
  ADD_POSITION_WITHHOLD: "ADD_POSITION_WITHHOLD",
  DELETE_POSITION_WITHHOLD: "DELETE_POSITION_WITHHOLD",

  UPDATE_ENTITY_SECTOR: "UPDATE_ENTITY_SECTOR",
  SET_JOB_CLASSIFICATION_EMPOYEES: "SET_JOB_CLASSIFICATION_EMPOYEES",
  SET_JOB_CLASSIFICATION_QUALIFICATIONS:
    "SET_JOB_CLASSIFICATION_QUALIFICATIONS",
  ADD_JOB_CLASSIFICATION_QUALIFICATION: "ADD_JOB_CLASSIFICATION_QUALIFICATION",
  DELETE_JOB_CLASSIFICATION_QUALIFICATION:
    "DELETE_JOB_CLASSIFICATION_QUALIFICATION",
  SET_JOB_CLASSIFICATION_EXPERIENCES: "SET_JOB_CLASSIFICATION_EXPERIENCES",
  ADD_JOB_CLASSIFICATION_EXPERIENCE: "ADD_JOB_CLASSIFICATION_EXPERIENCE",
  DELETE_JOB_CLASSIFICATION_EXPERIENCE: "DELETE_JOB_CLASSIFICATION_EXPERIENCE",
  DELETE_JOB_CLASSIFICATION_COMPETENCY: "DELETE_JOB_CLASSIFICATION_COMPETENCY",
  SET_JOB_CLASSIFICATION_COMPETENCIES: "SET_JOB_CLASSIFICATION_COMPETENCIES",
  ADD_JOB_CLASSIFICATION_COMPETENCY: "ADD_JOB_CLASSIFICATION_COMPETENCY",
  SET_COMPETENCIES: "SET_COMPETENCIES",
  SET_CERTIFICATE_FIELDS: "SET_CERTIFICATE_FIELDS",
  SET_CERTIFICATES: "SET_CERTIFICATES",
  ADD_JOB_CLASSIFICATION_CERTIFICATE: "ADD_JOB_CLASSIFICATION_CERTIFICATE",
  DELETE_JOB_CLASSIFICATION_CERTIFICATE:
    "DELETE_JOB_CLASSIFICATION_CERTIFICATE",
  SET_JOB_CLASSIFICATION_CERTIFICATES: "SET_JOB_CLASSIFICATION_CERTIFICATES",
  SET_POSITION_QUALIFICATIONS: "SET_POSITION_QUALIFICATIONS",
  SET_POSITION_CERTIFICATES: "SET_POSITION_CERTIFICATES",
  SET_POSITION_COMPETENCIES: "SET_POSITION_COMPETENCIES",
  SET_POSITION_EXPERIENCES: "SET_POSITION_EXPERIENCES",
  UPDATE_POSITION_WITHHOLD: "UPDATE_POSITION_WITHHOLD",
  UPDATE_UNIT_WITHHOLD: "UPDATE_UNIT_WITHHOLD",
  SET_ENTITY_SECTOR_ALLOWANCES: "SET_ENTITY_SECTOR_ALLOWANCES",
  ADD_ENTITY_SECTOR_ALLOWANCE: "ADD_ENTITY_SECTOR_ALLOWANCE",
  DELETE_ENTITY_SECTOR_ALLOWANCE: "DELETE_ENTITY_SECTOR_ALLOWANCE",
  SET_SHARED_SCOPES: "SET_SHARED_SCOPES",
  SET_SHARED_POSITION_ALLOWANCES: "SET_SHARED_POSITION_ALLOWANCES",
  ADD_SHARED_POSITION_ALLOWANCE: "ADD_SHARED_POSITION_ALLOWANCE",
  DELETE_SHARED_POSITION_ALLOWANCE: "DELETE_SHARED_POSITION_ALLOWANCE",
  DELETE_SHARED_POSITION_WITHHOLD: "DELETE_SHARED_POSITION_WITHHOLD",
  UPDATE_SHARED_POSITION_WITHHOLD: "UPDATE_SHARED_POSITION_WITHHOLD",
  ADD_SHARED_POSITION_WITHHOLD: "ADD_SHARED_POSITION_WITHHOLD",
  SET_SHARED_POSITION_WITHHOLDS: "SET_SHARED_POSITION_WITHHOLDS",
  SET_VERIFY_RSSB_NUMBER: "SET_VERIFY_RSSB_NUMBER",
};

export default types;
