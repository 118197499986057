import { defaultState } from "./state";
import eappeal from "./action-types";
import commonTypes from "../common/action-types";
const types = { ...commonTypes, ...eappeal };

const applealTypes = (
  applealTypesState = defaultState.applealTypes,
  action
) => {
  switch (action.type) {
    case types.SET_APPEAL_TYPES: {
      return action.data;
    }

    case types.CLEAN_STATE:
      return defaultState.applealTypes;
    default:
      return applealTypesState;
  }
};

const myAppeals = (myAppealsState = defaultState.myAppeals, action) => {
  switch (action.type) {
    case types.SET_MY_APPEALS: {
      return action.data;
    }

    case types.UPDATE_APPEAL: {
      const tmpMyAppealsState = [...myAppealsState];
      const index = tmpMyAppealsState.findIndex(
        ({ referenceNo }) => referenceNo === action.data.referenceNo
      );
      tmpMyAppealsState[index] = action.data;

      return tmpMyAppealsState;
    }

    case types.CLEAN_STATE:
      return defaultState.myAppeals;
    default:
      return myAppealsState;
  }
};

const appeal = (appealState = defaultState.appeal, action) => {
  switch (action.type) {
    case types.SET_APPEAL: {
      return action.data;
    }

    case types.CLEAN_STATE:
      return defaultState.appeal;
    default:
      return appealState;
  }
};

const appealDetails = (
  appealDetailsState = defaultState.appealDetails,
  action
) => {
  switch (action.type) {
    case types.SET_APPEAL_DETAILS: {
      return action.data;
    }

    case types.CLEAN_STATE:
      return defaultState.appealDetails;
    default:
      return appealDetailsState;
  }
};
const appealResolved = (
  appealResolvedState = defaultState.appealResolved,
  action
) => {
  switch (action.type) {
    case types.SET_APPEAL_RESOLVED: {
      return action.data;
    }

    case types.CLEAN_STATE:
      return defaultState.appealResolved;
    default:
      return appealResolvedState;
  }
};

export default {
  applealTypes,
  myAppeals,
  appeal,
  appealDetails,
  appealResolved,
};
