const types = {
  SET_PUBLIC_HOLIDAYS: "SET_PUBLIC_HOLIDAYS",
  SET_MY_LEAVE_PLANS: "SET_MY_LEAVE_PLANS",
  CHANGE_LEAVE_PLAN_STATUS: "CHANGE_LEAVE_PLAN_STATUS",
  CANCEL_LEAVE_PLAN: "CANCEL_LEAVE_PLAN",
  SET_LEAVE_TYPES: "SET_LEAVE_TYPES",
  SET_OTHER_LEAVE_REQUESTS: "SET_OTHER_LEAVE_REQUESTS",
  CANCEL_OTHER_LEAVE_REQUEST: "CANCEL_OTHER_LEAVE_REQUEST",
  SET_MY_OTHER_LEAVE_REQUESTS: "SET_MY_OTHER_LEAVE_REQUESTS",
};

export default types;
