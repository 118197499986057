import types from "./action-types";
import { defaultState } from "./state";

const env = (envState = defaultState.env, action) => {
  switch (action.type) {
    case types.SET_ENV:
      return action.data;

    default:
      return envState;
  }
};

const loading = (loadingState = defaultState.loading, action) => {
  switch (action.type) {
    case types.START_LOADING:
      return true;

    case types.END_LOADING:
      return false;

    case types.CLEAN_STATE:
      return defaultState.loading;

    default:
      return loadingState;
  }
};


const onWait = (waitState = defaultState.onWait, action) => {
  switch (action.type) {
    case types.START_WAIT:
      return true;

    case types.END_WAIT:
      return false;

    case types.CLEAN_STATE:
      return defaultState.onWait;

    default:
      return waitState;
  }
};

const document = (state = defaultState.document, action) => {
  switch (action.type) {
    case types.SET_DOCUMENT:
      return action.data;

    case types.CLEAN_STATE:
      return defaultState.document;

    default:
      return state;
  }
};

export default {
  loading,
  onWait,
  document,
};
