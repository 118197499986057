import types from "./action-types";
import axios from "axios";
import { showSuccess, showError } from "../../app/toastify";


export const getDocument = (docId,setIsSearching) => {
  return async (dispatch) => {
    try {
      setIsSearching(true);

      const { data } = await axios.get("/api/verify?docId="+docId);

      dispatch({
        type: types.SET_DOCUMENT,
        data: data,
      });

      setIsSearching(false);
    } catch (error) {
      setIsSearching(false);

      showError(error);
    }
  };
};

