import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { getDocument } from "../store/common/actions";

import { InputAdornment, Link, TextField } from "@mui/material";
import BackdropLoader from "./common/components/BackdropLoader";
import { isEmpty } from "lodash";
import searchImage from "./assets/search.png";
import DocumentItem from "./DocumentItem";
const VerifyDocument = (props) => {
  const { getDocument, document } = props;
  const [docId, setDocId] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.href.split("?")[1]);

    const docId =
      urlParams.get("docId") &&
      urlParams.get("docId").match(/^[A-Za-z0-9\s]+/)[0];
    if (docId) {
      setDocId(urlParams.get("docId"));
      getDocument(urlParams.get("docId"), setIsSearching);
    } else {
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <div className="container-fluid px-0">
        <div
          className="row justify-content-center pt-5 mx-0 px-0"
          style={{
            marginTop: "-142px",
            background: "#1976d2",
            height: "100%",
          }}
        >
          <div
            className="col-12 col-md-6 col-lg-4 bg-light rounded elevated p-4 mb-6"
            style={{
              border: "1px solid rgba(0, 0, 0, 0.125)",
            }}
          >
            <form>
              <h1 className="h3 mb-3 font-weight-normal">Verify document</h1>
              <h6 className="h6 mb-3 font-weight-bold">DocId: {docId}</h6>
              <div style={{ marginTop: "0px" }}>
                <div
                  style={{ backgroundColor: "#007bff", height: "2px" }}
                ></div>
                <div
                  style={{ backgroundColor: "#199e05", height: "1px" }}
                ></div>
                <div
                  style={{ backgroundColor: "#e5cb05", height: "2px" }}
                ></div>
              </div>

              <div className="col-12 mt-2">
                <BackdropLoader isLoading={isSearching} />
                {isEmpty(document) && (
                  <>
                    <p className="text-center">
                      <img src={searchImage} style={{ height: "200px" }} />
                    </p>
                    <p className="text-center mt-1">
                      <strong>No document found</strong>
                      <p>
                        <a className=" my-sm-0" href="/">
                          Search document
                        </a>
                      </p>
                    </p>
                  </>
                )}
                {!isEmpty(document) && (
                  <>
                    <DocumentItem document={document} />
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ document }) => {
  return { document };
};
export default connect(mapStateToProps, {
  getDocument,
})(VerifyDocument);
