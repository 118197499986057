import React, { useState } from "react";

import { Switch, Route, useHistory, useLocation } from "react-router-dom";

import Home from "./Home";
import ToastifyMessage from "./common/components/ToastifyMessage";
import OfflineMessage from "./common/components/OfflineMessage";
import TopProgressBar from "./common/components/TopProgressBar";
import Header from "./common/components/Header";

import Footer from "./common/components/Footer";


import Header2 from "./common/components/Header2";
import VerifyDocument from "./VerifyDocument";

function App(props) {
  const [drawerState, setDrawerState] = useState(false);

  return (
    <>
      <div id="main" style={{ overflowX: "visible" }}>
        <TopProgressBar />

        <ToastifyMessage />

        <OfflineMessage />

        <Header drawerState={drawerState} setDrawerState={setDrawerState} />

        <Header2 />

        <Switch>
          <Route path="/verify" component={VerifyDocument} />
          <Route path="/" component={Home} />
        </Switch>

        <Footer />
      </div>
    </>
  );
}

export default App;
