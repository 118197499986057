const types = {
  START_LOADING: "START_LOADING",
  END_LOADING: "END_LOADING",
  CLEAN_STATE: "CLEAN_STATE",
  SET_ENV: "SET_ENV",
  START_WAIT: "START_WAIT",
  END_WAIT: "END_WAIT",
  SET_DOCUMENT: "SET_DOCUMENT",
};

export default types;
