import React, { useState } from "react";
import { connect } from "react-redux";

import { getDocument } from "../store/common/actions";

import { InputAdornment, TextField } from "@mui/material";
import BackdropLoader from "./common/components/BackdropLoader";
import { isEmpty } from "lodash";
import searchImage from "./assets/search.png";
import DocumentItem from "./DocumentItem";
const Home = (props) => {
  const { getDocument, document } = props;

  const [docId, setDocId] = useState("");
  const [isSearching, setIsSearching] = useState(false);

  const searchDocument = async (e) => {
    e.preventDefault();
    getDocument(docId, setIsSearching);
  };

  return (
    <React.Fragment>
      <div className="container-fluid px-0">
        <div
          className="row justify-content-center pt-5 mx-0 px-0"
          style={{
            marginTop: "-142px",
            background: "#1976d2",
            height: "100%",
          }}
        >
          <div
            className="col-12 col-md-6 col-lg-4 bg-light rounded elevated p-4 mb-6"
            style={{
              border: "1px solid rgba(0, 0, 0, 0.125)",
            }}
          >
            <form onSubmit={searchDocument}>
              <h1 className="h3 mb-3 font-weight-normal">Find document</h1>
              <div style={{ marginTop: "0px" }}>
                <div
                  style={{ backgroundColor: "#007bff", height: "2px" }}
                ></div>
                <div
                  style={{ backgroundColor: "#199e05", height: "1px" }}
                ></div>
                <div
                  style={{ backgroundColor: "#e5cb05", height: "2px" }}
                ></div>
              </div>

              <div className="row mt-4">
                <div className="col-12">
                  <TextField
                    fullWidth
                    size="small"
                    rows={4}
                    name="searchTerm"
                    label={`Enter document number`}
                    placeholder={`Enter document number`}
                    variant="outlined"
                    className="font-weight-bold mb-2"
                    value={docId || ""}
                    onChange={(e) => {
                      setDocId(e.target.value || "");
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <span
                            style={{
                              borderRadius: "0px",
                            }}
                            className={`p-0`}
                          >
                            <button
                              style={{
                                position: "relative",
                                right: " -11px",
                              }}
                              disabled={isSearching || !docId}
                              type="submit"
                              className="btn btn-primary btn-block text-uppercase btn-sm d-flex align-items-center "
                            >
                              <span className="material-icons">search</span>
                              Search
                            </button>
                          </span>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>

              <div className="col-12 mt-2">
                <BackdropLoader isLoading={isSearching} />
                {isEmpty(document) && (
                  <>
                    <p className="text-center">
                      <img src={searchImage} style={{ height: "200px" }} />
                    </p>
                    <p className="text-center mt-1">
                      <strong>No document found</strong>
                    </p>
                  </>
                )}
                {!isEmpty(document) && (
                  <>
                    <DocumentItem document={document} />
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ document }) => {
  return { document };
};
export default connect(mapStateToProps, {
  getDocument,
})(Home);
